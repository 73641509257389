
import notifications from '~/mixins/notifications'

export default {
  layout: 'login',
  auth: false,
  middleware: ['isUser'],
  mixins: [notifications],
  head () {
    return {
      titleTemplate: this.$t('login') + ' - %s',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.login')
        }
      ],
    }
  },
  data () {
    return {
      login: {
        email: '',
        password: ''
      },
      error: false,
      errorMessage: '',
      passwordReset: false,
      confirmStatus: null,
      loader: false,
      loginGoogleUrl: process.env.BACKEND_URL + '/api/login/google',
    }
  },
  mounted () {
    this.$auth.logout().then(() => {
      this.deleteCurrentPushSub()
    })

    if (this.$route.query.confirm_status != undefined) {
      if (this.$route.query.confirm_status == 0) {
        this.confirmStatus = 'success'
      }

      if (this.$route.query.confirm_status == 1) {
        this.confirmStatus = 'alreadyVerified'
      }
    }

    if (this.$route.query.password_reset == 1) {
      this.passwordReset = true
    }
  },
  methods: {
    async userLogin () {
      this.loader = true
      try {
        this.passwordReset = false
        this.confirmStatus = null
        this.error = false
        this.errorMessage = ''
        await this.$auth.loginWith('laravelSanctum', { data: this.login })
        this.$router.push(this.localePath('/users'))
      } catch (error) {
        this.$auth.logout()

        if (error.response) {
          this.error = error.response.status
          this.errorMessage = error.response.data.message
        } else {
          this.error = true
        }
      }
      this.loader = false
    },

  },
}
